<template>
  <div class="s-shops-container">
    <div
      v-for="shop in shops"
      :key="shop.shopName"
      class="s-shop-item"
    >
      <a :href="shop.shopLink">{{ shop.shopName }}</a>
    </div>
  </div>
</template>

<script>
  export default {
    data: () => ({
      shops: [
        { shopName: 'Dade', shopLink: '#' },
        { shopName: 'Broward', shopLink: '#' },
        { shopName: 'Palm Beach', shopLink: '#' },
        { shopName: 'Brevard', shopLink: '#' },
        { shopName: 'Indian River', shopLink: '#' },
        { shopName: 'Saint Lucie', shopLink: '#' },
        { shopName: 'Martin', shopLink: '#' },
        { shopName: 'Pinellas', shopLink: '#' },
        { shopName: 'Hillsborough', shopLink: '#' },
        { shopName: 'Manatee', shopLink: '#' },
        { shopName: 'Sarasota', shopLink: '#' },
        { shopName: 'Charlotte', shopLink: '#' },
        { shopName: 'Lee', shopLink: '#' },
        { shopName: 'Collier', shopLink: '#' },
        { shopName: 'Monroe', shopLink: '#' },
        { shopName: 'Hardee', shopLink: '#' },
        { shopName: 'Highlands', shopLink: '#' },
        { shopName: 'De Soto', shopLink: '#' },
        { shopName: 'Glades', shopLink: '#' },
        { shopName: 'Hendry', shopLink: '#' },
        { shopName: 'Duval', shopLink: '#' },
        { shopName: 'Clay', shopLink: '#' },
        { shopName: 'Putnam', shopLink: '#' },
        { shopName: 'St. Johns', shopLink: '#' },
        { shopName: 'Flagler', shopLink: '#' },
        { shopName: 'Volusia', shopLink: '#' },
        { shopName: 'Seminole', shopLink: '#' },
        { shopName: 'Orange', shopLink: '#' },
        { shopName: 'Osceola', shopLink: '#' },
        { shopName: 'Polk', shopLink: '#' },
        { shopName: 'Alachua', shopLink: '#' },
        { shopName: 'Lake', shopLink: '#' },
        { shopName: 'Marion', shopLink: '#' },
        { shopName: 'Sumter', shopLink: '#' },
        { shopName: 'Hermando', shopLink: '#' },
        { shopName: 'Pasco', shopLink: '#' }
      ]
    })
  }
</script>

<style scoped lang="postcss">
  .s-shops-container {
    display: flex;
    width: 100%;
    height: 196px;
    margin-top: 84px;
    flex-wrap: wrap;
    flex-direction: column;

    @media screen and (max-width: 1440px) {
      height: 250px;
    }

    @media screen and (max-width: 930px) {
      height: 360px;
    }

    @media screen and (max-width: 767px) {
      height: 520px;
      margin: 0 auto;
      margin-top: 80px;
      display: none;
    }

    @media screen and (max-width: 524px) {
      width: 100%;
      margin-top: inherit;
      align-items: center;
      display: none;
    }

    @media screen and (max-width: 320px) {
      display: none;
    }

    .s-shop-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      color: #000;
      @apply font-alt;
      width: 104px;
      margin-right: 34px;
      min-height: 40px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 40px;

      @media screen and (max-width: 1440px) {
        width: 116px;
      }

      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }

      @media screen and (max-width: 524px) {
        justify-content: center;
        margin-right: 0;
      }
    }
  }
</style>
