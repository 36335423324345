<template>
  <div class="s-footer-social-container">
    <div
      v-for="socials of GET_HEADER_DATA.partner_with_us.footer_partner_with_us_socials"
      :key="socials.footer_partner_with_us_social_url"
      class="s-footer-social-container__link"
    >
      <a
        target="_blank"
        :href="socials.footer_partner_with_us_social_url"
      >
        <NuxtImg
          class="fill-white"
          :src="$correctedImageUrl(socials.footer_partner_with_us_social_icon).cleanUrl"
          alt="Find the Flowery on Social"
          loading="lazy"
        />
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    data() {
      return {}
    },
    computed: {
      ...mapGetters(['GET_HEADER_DATA'])
    }
  }
</script>

<style scoped lang="postcss">
  .s-footer-social-container {
    margin-top: 20px;
    display: flex;

    .footer-title {
      @apply font-main;
      font-weight: 700;
      text-transform: uppercase;
      @apply text-black;
    }

    @media screen and (max-width: 640px) {
      width: 100%;
      justify-content: space-around;
    }

    &__link {
      margin-right: 30px;

      img {
        height: 20px;
      }

      @media screen and (max-width: 640px) {
        margin-right: 0;
      }
    }
  }
</style>
