<template>
  <div class="s-subfooter-container">
    © Copyright {{ currentYear }},
    <a href="#">The Flowery</a>
    <span>|</span>
    <NuxtLink to="/privacy-policy">Privacy Policy</NuxtLink>
    <span>|</span>
    <span class="s-mobile-span">|</span>

    <a
      href="https://support.Theflowery.co"
      target="_blank"
      rel="noopener"
    >
      Return Policy
    </a>
    <span>|</span>
    <NuxtLink to="/terms">Terms & Conditions</NuxtLink>
    <span>|</span>
    <NuxtLink to="/eula">EULA</NuxtLink>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'SubFooter',
    data() {
      return {
        currentYear: new Date().getFullYear()
      }
    },
    methods: {
      ...mapActions('fireworks', ['xStartFourTwentyFireworks'])
    }
  }
</script>

<style scoped lang="postcss">
  .s-subfooter-container {
    margin-top: 27px;
    display: flex;
    color: #000;
    @apply font-alt;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    flex-wrap: wrap;

    .s-mobile-span {
      display: none;

      @media screen and (max-width: 350px) {
        display: inline;
      }
    }

    @media screen and (max-width: 1024px) {
      font-size: 12px;
    }

    @media screen and (max-width: 767px) {
      justify-content: center;

      span {
        display: none;
      }
    }

    a {
      margin: 0 6px;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
</style>
