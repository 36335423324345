<template>
  <div :class="[{ 'bg-black text-white': useDarkTheme }, 'footer']">
    <div class="s-container relative md:top-0 pt-16 md:pt-48">
      <PageFooterSubscribe />
      <PageFooterNavigation class="relative top-10 md:top-0" />
      <div class="s-container__main">
        <div class="s-container__main_content">
          <PageFooterLogos @hyperlink="$emit('hyperlink', $event)" />
          <PageFooterShops />
          <PageFooterSubFooter />

          <div class="flex justify-center md:justify-start mt-10">
            <a
              href="https://getsalve.co/"
              target="_blank"
              class="border border-gray-700 rounded-full inline-flex items-center px-6 py-2"
            >
              <span class="text-gray-dark font-main font-semibold text-lg pr-2 tracking-tight">Powered by</span>
              <SalveImage
                width="110"
                height="26"
                url="/salve-logo.svg"
                alt="Salve TM"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      useDarkTheme: {
        type: Boolean,
        default: false
      }
    },
    mounted() {
      this.userInteracted = false
      // Attach event listeners if on client side
      if (process.client) {
        const eventTypes = ['mousemove', 'touchstart', 'click']
        const loadHandler = () => {
          this.userInteracted = true // Set flag to true on interaction
          this.loadScripts()
        }

        // Attach to multiple event types to cover different user interactions
        eventTypes.forEach((eventType) => {
          document.addEventListener(eventType, loadHandler, { once: true })
        })

        // Set a timeout to check for script load and user interaction
        setTimeout(() => {
          if (!this.userInteracted && !this.isZendeskScriptLoaded()) {
            this.loadScripts()
          }
        }, 4000) // Wait for 4 seconds
      }
    },
    methods: {
      loadScripts() {},
      isZendeskScriptLoaded() {
        // Check if the Zendesk script is loaded
        return !!document.getElementById('ze-snippet')
      }
    }
  }
</script>

<style scoped lang="postcss">
  @media screen and (max-width: 1023px) {
    #dummy-zendesk {
      display: none;
    }
  }

  .s-container {
    display: flex;
    flex-direction: column;

    &__main {
      width: 100%;

      background-color: #fff;

      &_content {
        width: 1440px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 114px 98px 60px 98px;

        @media screen and (max-width: 1440px) {
          width: 100%;
          padding: 100px 80px 40px 80px;
        }

        @media screen and (max-width: 768px) {
          padding: 77px 27px 33px 27px;
          #dummy-zendesk {
            display: none;
          }
        }
      }
    }
  }
</style>
