<template>
  <div
    class="s-footer-subscribe-container relative top-10 md:top-0"
    :style="backgroundStyles"
  >
    <div class="s-footer-subscribe-container__text">
      Join our mailing list to stay up-to-date <br class="display_none" />
      on Flowery strain drops and specials.
    </div>
    <PageFooterSubscribeForm />
    <SalveImage
      url="/subscribe-spraypaint.png"
      :modifiers="{ w: 600 }"
      alt="gfaff"
      class="s-footer-subscribe-container__graff"
      loading="lazy"
    />
  </div>
</template>

<script>
  import { imageData } from '@/utils/images'

  export default {
    data() {
      const { path, provider } = imageData('/footer_subscribe_bg.jpg')

      return {
        // Initial low-quality background image
        backgroundImageUrl: this.$img(path, { quality: 5 }, { provider })
      }
    },
    computed: {
      backgroundStyles() {
        return {
          backgroundImage: `url('${this.backgroundImageUrl}')`
        }
      }
    },
    mounted() {
      this.loadHighQualityBackgroundImage()
    },
    methods: {
      loadHighQualityBackgroundImage() {
        const { path, provider } = imageData('/footer_subscribe_bg.jpg')
        // Logic to load high-quality image
        const highQualityImageUrl = this.$img(path, { quality: 50 }, { provider })

        // Create a new Image object
        const img = new Image()
        img.onload = () => {
          // Once the high-quality image is loaded, update the backgroundImageUrl
          this.backgroundImageUrl = highQualityImageUrl
        }
        img.src = highQualityImageUrl
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-footer-subscribe-container {
    width: 100%;
    height: 250px;
    margin: 0 auto;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 1440px) {
      width: 100%;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 310px;
    }

    @media screen and (max-width: 640px) {
      height: 200px;
      @apply mt-20 text-center;
    }

    &__main-img {
      position: absolute;
      bottom: 0;
      z-index: -5;
      height: 250px;
      overflow-x: hidden;
      width: 100%;

      @media screen and (max-width: 1024px) {
        height: 310px;
        width: 100vw;
      }
      @media screen and (max-width: 768px) {
        bottom: -70px;
        height: 256px;
      }

      @media screen and (max-width: 640px) {
        height: 150px;
        bottom: 210px;
        left: 0;
        right: 0;
      }
    }

    &__graff {
      position: absolute;
      width: 600px;
      top: -220px;
      left: 100px;

      @media screen and (max-width: 1024px) {
        width: 600px;
        left: 0;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        top: -19px;
      }

      @media screen and (max-width: 640px) {
        top: -150px;
        left: 0;
        right: 0;
        text-align: center;
        margin: 0 auto;
      }
    }

    &__text {
      position: absolute;
      top: -70px;
      left: 650px;
      @apply font-alt;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;

      @media screen and (max-width: 1024px) {
        top: -70px;
        left: 550px;
      }

      @media screen and (max-width: 768px) {
        top: 55px;
        font-size: 12px;
      }

      @media screen and (max-width: 580px) {
        line-height: 20px;
      }

      @media screen and (max-width: 640px) {
        width: 237px;
        text-align: center;
        top: 200px;
        left: 17%;
        display: none;
      }

      .display_none {
        @media screen and (max-width: 580px) {
          display: none;
        }
      }
    }
  }
</style>
