<template>
  <div
    class="s-footer-navigation"
    :style="backgroundStyles"
  >
    <div class="s-footer-navigation__container">
      <div class="s-footer-navigation__container_logo">
        <SalveImage
          url="/images/flowery-logo.png"
          alt="Flowery Logo"
          loading="lazy"
        />
      </div>
      <div class="s-footer-navigation__container_links">
        <PageFooterNavigationLinks />
        <PageFooterContactsWrapper />
      </div>
    </div>
  </div>
</template>

<script>
  import { imageData } from '@/utils/images'

  export default {
    computed: {
      backgroundStyles() {
        const { path, provider } = imageData('/grunge-bg.jpg')
        return {
          backgroundImage: `url('${this.$img(path, { quality: 50 }, { provider })}')`
        }
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-footer-navigation {
    width: 100%;
    margin: 0 auto;
    background-size: cover;

    &__container {
      width: 1440px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      @apply pb-20;

      @media screen and (max-width: 1440px) {
        width: 100%;
      }

      @media screen and (max-width: 640px) {
        position: relative;

        @apply flex-col flex justify-center w-full items-center text-center;
      }

      &_links {
        display: flex;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
      }

      &_logo {
        width: 457px;

        @media screen and (max-width: 1024px) {
          width: 367px;
        }

        @media screen and (max-width: 640px) {
          top: inherit;
          width: 250px;
          left: inherit;
          @apply mb-10;
        }

        img {
          margin-left: 90px;
          margin-top: 108px;

          @media screen and (max-width: 1240px) {
            margin-left: 50px;
          }

          @media screen and (max-width: 1024px) {
            margin-left: 0;
          }
        }
      }
    }
  }
</style>
