<template>
  <div class="s-footer-shops-container">
    <div class="footer-title mb-2">Shop By Category</div>
    <div class="flex flex-wrap lg:flex-row">
      <div
        v-for="category of GET_HEADER_DATA.footer_category"
        :key="category.footer_category_title"
        class="s-footer-shop-category cursor-pointer w-1/2 lg:w-auto p-2"
      >
        <SalveLink
          v-if="!category.footer_category_title.includes('Merch')"
          :to="getCategoryLink(category.footer_category_url)"
          class="mr-0 md:mr-5 mb-5 md:mb-0 [&>a]:items-center [&>a]:w-full [&>a]:flex [&>a]:flex-row [&>a]:lg:block"
          @hyperlink="handleCategoryClick"
        >
          <SalveImage
            :url="category.footer_category_image.url"
            :modifiers="{ w: 160 }"
            alt="preroll_icon"
            class="footer-category"
            loading="lazy"
          />
          <div class="s-category-text pl-2">
            {{ category.footer_category_title }}
          </div>
        </SalveLink>

        <SalveLink
          v-if="category.footer_category_title.includes('Merch')"
          :to="getCategoryLink(category.footer_category_url)"
          class="mr-0 md:mr-5 mb-5 md:mb-0 [&>a]:items-center [&>a]:w-full [&>a]:flex [&>a]:flex-row [&>a]:lg:block"
        >
          <SalveImage
            :url="category.footer_category_image.url"
            :modifiers="{ w: 160 }"
            alt="preroll_icon"
            class="footer-category"
            loading="lazy"
          />
          <div class="s-category-text pl-2">
            {{ category.footer_category_title }}
          </div>
        </SalveLink>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    emits: ['hyperlink'],
    computed: {
      ...mapGetters(['GET_HEADER_DATA']),
      ...mapGetters('user-region', ['xSelectedLocationId'])
    },
    methods: {
      getCategoryLink(link) {
        // If this is a full hyperlink just return as is
        if (link.slice(0, 4) === 'http') {
          return link
        }

        const locationId = this.xSelectedLocationId || '99' // Use '99' as default if not defined

        // Check if the link contains 'categories'
        if (link.includes('categories')) {
          // Extract the category value, accounting for both encoded and unencoded formats
          const categoryMatch = link.match(/categories(?:%5B%5D|\[\])=([^&]+)/)
          if (categoryMatch && categoryMatch[1]) {
            const category = decodeURIComponent(categoryMatch[1])

            // Handle special cases for Pre-Rolled and Handroll
            if (category === 'Pre-Rolled' || category === 'Handroll') {
              const subCategory = category === 'Pre-Rolled' ? 'Pre-Rolls' : 'Hand-Rolls'
              return `/shop?location_id=${locationId}&filter_groups%5B0%5D%5Bcategories%5D%5B%5D=Flower&filter_groups%5B0%5D%5Bsub_categories%5D%5B%5D=${subCategory}`
            }

            // Construct the new link format with location_id for other categories
            return `/shop?location_id=${locationId}&filter_groups%5B0%5D%5Bcategories%5D%5B%5D=${category}`
          }
        }

        // If it doesn't contain 'categories' or we couldn't extract the category,
        // return the original link as a relative path from shop, still including location_id
        const basePath = link.startsWith('/') ? link : `/${link}`
        return `${basePath}${basePath.includes('?') ? '&' : '?'}location_id=${locationId}`
      },
      handleCategoryClick(link) {
        this.$emit('hyperlink', link)
        this.scrollToTop()
      },
      scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-footer-shops-container {
    @apply flex flex-col justify-center md:justify-start text-center md:text-left mt-10 lg:mt-0 mb-10 ml-0 lg:ml-10;

    .footer-title {
      @apply font-main;
      font-weight: 700;
      text-transform: uppercase;
      @apply text-black mb-5;
    }

    .s-footer-shop-category {
      @apply text-center md:text-left;
      a {
        @apply block mr-0 lg:mr-5;
      }
      img {
        height: 80px;
        @apply hidden md:block;
      }
      .s-category-text {
        color: #000;
        @apply font-alt;
        @apply font-semibold mt-0 text-center;
      }
    }
  }
</style>
