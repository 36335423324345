<template>
  <div class="s-footer-navigation-links">
    <div class="footer-title">Links</div>
    <div class="s-footer-navigation-links__wrapper">
      <div
        v-for="link in GET_HEADER_DATA.footer_links"
        :key="link.footer_link_url"
        class="s-footer-navigation-links__wrapper_link"
      >
        <a :href="link.footer_link_url">{{ link.footer_link_text }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    data() {
      return {}
    },
    computed: {
      ...mapGetters(['GET_HEADER_DATA'])
    }
  }
</script>

<style scoped lang="postcss">
  .s-footer-navigation-links {
    width: 440px;
    height: 100%;
    margin-left: 20px;
    margin-top: 150px;
    @apply md:px-5;

    @media screen and (max-width: 1024px) {
      width: 300px;
      margin-left: 0;
      height: auto;
    }

    @media screen and (max-width: 640px) {
      margin-top: inherit;
      text-align: center;
    }

    .footer-title {
      @apply font-main;
      font-weight: 700;
      text-transform: uppercase;
      @apply text-white mb-3 md:mb-3;
    }

    &__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 200px;

      @media screen and (max-width: 772px) {
        text-align: center;
        align-items: center;
        height: 300px;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        display: block !important;
      }

      &_link {
        width: fit-content;
        @apply font-alt;
        font-size: 16px;
        text-align: left;
        color: white;
        @apply leading-loose md:w-auto w-1/3 mx-1 md:mx-0 font-bold;

        @media screen and (max-width: 1024px) {
          font-size: 14px;
        }
      }
    }
  }
</style>
