import env from '@/environment'
const isDev = process.env.NODE_ENV === 'development'

const parseUrl = (url) => {
  try {
    return new URL(url)
  } catch {
    return null
  }
}

export const imageData = (url) => {
  try {
    const urlData = parseUrl(url)
    const isAbsoluteURL = urlData !== null
    const path = isAbsoluteURL ? urlData.pathname : url
    const provider = isDev && !isAbsoluteURL ? null : 'imagekit'
    const isGif = path.endsWith('.gif')
    const mp4Path = isGif ? `${env.IMAGE_KIT_URL}${path}/ik-gif-video.mp4` : null

    return {
      provider,
      path,
      isGif,
      mp4Path
    }
  } catch {
    return {
      isGif: false,
      mp4Path: null,
      provider: null,
      path: '/images/404.png'
    }
  }
}
