<template>
  <div class="s-footer-contacts-container text-white">
    <div class="footer-title">Contact Us</div>
    <div
      v-if="GET_HEADER_DATA.footer_contact_us?.footer_contact_us_phone"
      class="s-footer-contacts-container__phone"
    >
      {{ GET_HEADER_DATA.footer_contact_us.footer_contact_us_phone }}
    </div>
    <div
      v-if="GET_HEADER_DATA.footer_contact_us?.footer_contact_us_email"
      class="s-footer-contacts-container__mail"
    >
      <a :href="'mailto:' + GET_HEADER_DATA?.footer_contact_us.footer_contact_us_email">{{
        GET_HEADER_DATA.footer_contact_us.footer_contact_us_email
      }}</a>
    </div>
    <div class="s-footer-contacts-container__desc">Proudly Grown in Homestead, FL</div>
    <div
      v-if="GET_HEADER_DATA.footer_contact_us?.footer_contact_us_hours"
      class="s-footer-contacts-container__hours"
    >
      Hours: {{ GET_HEADER_DATA.footer_contact_us.footer_contact_us_hours }}
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    data() {
      return {}
    },
    computed: {
      ...mapGetters(['GET_HEADER_DATA'])
    }
  }
</script>

<style scoped lang="postcss">
  .s-footer-contacts-container {
    .footer-title {
      @apply font-main;
      font-weight: 700;
      text-transform: uppercase;
      @apply text-white mb-2;
    }

    @media screen and (max-width: 768px) {
      margin-top: 30px;
    }

    @media screen and (max-width: 640px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__desc {
      display: none;

      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    &__mail {
      text-decoration: underline;
    }
  }
</style>
