<template>
  <div class="s-footer-media-container">
    <div class="footer-title">Partner With Us</div>
    <div
      v-if="GET_HEADER_DATA.partner_with_us?.footer_partner_with_us_links"
      class="flex flex-row text-white"
    >
      <div
        v-for="link of GET_HEADER_DATA.partner_with_us.footer_partner_with_us_links"
        :key="link.footer_partner_with_us_link_url"
        class="s-footer-media-container__link"
      >
        <a :href="link.footer_partner_with_us_link_url">{{ link.footer_partner_with_us_link_title }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    data() {
      return {}
    },
    computed: {
      ...mapGetters(['GET_HEADER_DATA'])
    }
  }
</script>

<style scoped lang="postcss">
  .s-footer-media-container {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    @apply text-center md:text-left;

    .footer-title {
      @apply font-main;
      font-weight: 700;
      text-transform: uppercase;
      @apply text-white;
    }

    @media screen and (max-width: 545px) {
      width: 100%;
      justify-content: space-around;
    }

    &__link {
      margin-right: 30px;
      display: flex;
      flex-direction: row;
      @apply text-center md:text-left w-full justify-center md:justify-start;

      @media screen and (max-width: 545px) {
        margin-right: 0;
      }
    }
  }
</style>
