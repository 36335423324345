<template>
  <LoadingScreenPromiseLoader
    v-slot="{ loadPromise }"
    @complete="subscribed"
  >
    <section class="relative top-10 md:top-0 h-full flex items-center w-full z-10">
      <form
        class="flex flex-col md:flex-row rounded-xl md:rounded-full py-3 px-3 bg-white w-10/12 md:w-full max-w-md md:max-w-3xl mx-auto mt-24"
      >
        <label class="flex-auto">
          <input
            v-model="form.name"
            placeholder="Your Name"
            class="w-full font-medium py-4 px-2 text-lg text-black placeholder-black border rounded-xl rounded-b-none md:rounded-full md:rounded-r-none pl-6"
            type="text"
          />
          <span
            v-if="nameError"
            class="block text-sm md:text-xs mt-0 md:-mt-5 text-left bg-pink-50 md:bg-transparent text-pink-dark pl-6 py-3 md:py-0"
          >
            {{ nameErrorMsg }}
          </span>
        </label>

        <label class="flex-auto">
          <input
            v-model="form.email"
            placeholder="Your Email address"
            class="w-full font-medium py-4 px-2 text-lg text-black placeholder-black border-l border-r md:border-l-0 md:border-r-0 md:border-t md:border-b pl-6"
            type="text"
          />
          <span
            v-if="emailError"
            class="block text-sm md:text-xs mt-0 md:-mt-5 text-left bg-pink-50 md:bg-transparent text-pink-dark pl-6 py-3 md:py-0"
          >
            {{ emailErrorMsg }}
          </span>
        </label>

        <div class="flex-auto">
          <button
            type="submit"
            class="h-full w-full font-medium py-4 px-2 text-lg bg-teal text-white rounded-xl md:rounded-full rounded-t-none md:rounded-l-none"
            @click.prevent="subscribeClicked(loadPromise)"
          >
            Sign-up!
          </button>
        </div>
      </form>

      <transition name="slide-fade">
        <NuxtLink
          v-if="showSuccess"
          :to="'/cart'"
          class="shadow-md bg-teal text-white flex flex-col fixed bottom-40 md:bottom-auto md:top-40 right-10 bg-white p-5"
        >
          <div class="flex flex-row justify-between">
            <div class="flex flex-row">
              <img
                alt="Added"
                class="mr-2"
                src="@/assets/icon/svg/added.svg?url"
              />
              <span class="text-sm font-bold">Subscribed To Newsletter</span>
            </div>
            <img
              alt="Delete"
              class="ml-10 cursor-pointer"
              src="@/assets/icon/svg/delete.svg?url"
              @click="showSuccess = false"
            />
          </div>
        </NuxtLink>
      </transition>
    </section>
  </LoadingScreenPromiseLoader>
</template>

<script>
  import { mapActions } from 'vuex'
  import Validator from '@/utils/validation'

  const VALIDATION_RULES = {
    name: 'required|string',
    email: 'required|email'
  }

  export default {
    name: 'SubscribeForm',

    data() {
      return {
        errors: {},
        form: {
          name: '',
          email: ''
        },
        showSuccess: false
      }
    },

    computed: {
      emailError() {
        return 'email' in this.errors
      },
      nameError() {
        return 'name' in this.errors
      },
      emailErrorMsg() {
        return this.errors?.email[0]
      },
      nameErrorMsg() {
        return this.errors?.name[0]
      }
    },

    methods: {
      ...mapActions('pageutil', ['xSubscribeToNewsletter']),

      subscribeClicked(loader) {
        if (this.validate()) {
          loader(this.xSubscribeToNewsletter(this.form))
        }
        console.log(':::: submit', this.errors)
      },

      validate() {
        this.errors = {}
        const validation = new Validator(this.form, VALIDATION_RULES)
        const passes = validation.passes()

        if (!passes) {
          this.errors = validation.errors.errors
        }

        return passes
      },

      subscribed() {
        this.form = {
          name: '',
          email: ''
        }
        this.showSuccess = true
        setTimeout(() => {
          this.showSuccess = false
        }, 4000)
      }
    }
  }
</script>
